<template>
  <div class="testimonial">
    <img :src="img" alt="" class="testimonial__img" />
    <div class="testimonial__content">
      <blockquote cite="">
        {{ quote }}
      </blockquote>
      <p>{{ author }}</p>
      <p>{{ job }}</p>
      <Button :link="link" text="Read article" targetBlank />
    </div>
  </div>
</template>

<script>
import Button from "@/components/UI/RegularButton";
export default {
  components: { Button },
  props: {
    img: {
      type: String,
      required: true,
    },
    quote: {
      type: String,
      required: true,
    },
    author: {
      type: String,
      required: true,
    },
    job: {
      type: String,
    },
    link: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.testimonial {
  display: grid;
  grid-template-columns: 392px repeat(2, 1fr);
  grid-template-areas: "img content content";
  align-items: center;
  gap: 32px 64px;

  .testimonial__img {
    grid-area: img;
    max-width: 392px;
    width: 100%;
    border-radius: 12px;
  }

  .testimonial__content {
    grid-area: content;
    text-align: left;
    display: flex;
    flex-direction: column;

    blockquote {
      margin-bottom: 16px;
      max-width: 580px;
      font-size: 32px;
      line-height: 40px;
      font-weight: 500;
      text-align: left;
      text-indent: -0.5em;

      &::before {
        content: open-quote;
      }
      &::after {
        content: close-quote;
      }
    }

    & p:first-of-type {
      margin-bottom: 4px;
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
    }

    & p:last-of-type {
      margin-bottom: 32px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.5);
    }

    @supports (hanging-punctuation: first) {
      blockquote {
        text-indent: 0;
        hanging-punctuation: first;
      }
    }
  }

  @media screen and (max-width: 1070px) {
    grid-template-columns: 1fr;
    grid-template-areas: "img" "content";
    place-items: center;

    .testimonial__content {
      align-items: center;
      text-align: center;

      blockquote {
        padding-left: 20px;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 768px) {
  }
}
</style>
