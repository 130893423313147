<template>
  <section class="posts padding">
    <div class="container">
      <div class="posts__content">
        <h2 class="posts__title">Latest blog posts</h2>
        <p class="posts__subtitle">Tips, guides, best practices and news.</p>
        <div class="posts__wrapper">
          <BlogPostCard
            v-for="(post, i) in posts.splice(0, 3)"
            :key="i"
            :url="post.url"
            :img="post.image"
            :date="post.date"
            :title="post.title"
            :description="post.description"
          />
        </div>
        <Button
          :link="blogUrl"
          text="View all posts"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Button from "@/components/UI/RegularButton";
import BlogPostCard from "../BlogPostCard.vue";
import { POSTS } from "@/utils/posts.js";

export default {
  components: { Button, BlogPostCard },
  data() {
  const { protocol, hostname, port } = window.location;
  const domain = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
  return {
    blogUrl: `${domain}/blog/`
  };
},
  computed: {
    posts() {
      return POSTS;
    },
  },
  methods: {
    getImageUrl(imagePath) {
      return require(`${imagePath}`);
    },
  },
};
</script>

<style scoped>
* {
  background: #fefefe;
}

.padding {
  padding: 0 20px;
}

.container {
  width: 100%;
  max-width: 1224px;
  margin: 0 auto;
}

.posts__wrapper {
  margin: 48px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 48px;
}

.posts__content {
  padding: 120px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.posts__title {
  font-weight: 600;
  font-size: 48px;
  line-height: 62px;
  letter-spacing: -0.02em;
  color: #17191d;
  text-align: center;
  margin-bottom: 12px;
}

.posts__subtitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #17191d;
}

@media screen and (max-width: 600px) {
  .padding {
    padding: 0 16px;
  }
  .posts__wrapper {
    margin-top: 40px;
  }
  .posts__content {
    padding: 60px 0 80px;
  }
  .posts__title {
    margin-bottom: 12px;
    font-size: 36px;
    line-height: 44px;
  }
}
</style>
