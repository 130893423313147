<template>
  <a :href="url" class="blog-post">
    <img :src="img" :alt="`${title} post image`" />
    <span>{{ date }}</span>
    <p>{{ title }}</p>
    <p>{{ description }}</p>
  </a>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true
    }
  },
};
</script>

<style scoped>
.blog-post {
  max-width: 376px;
  display: flex;
  flex-direction: column;

  img {
    margin-bottom: 24px;
    width: 100%;
    aspect-ratio: 16/8.5;
    object-fit: cover;
    border-radius: 12px;
  }

  span {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.5);
  }

  p {
    color: #17191d;

    &:first-of-type {
      margin-bottom: 8px;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;

      @media screen and (max-width: 600px) {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
      }
    }

    &:last-of-type {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
</style>
