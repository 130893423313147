<template>
  <a class="button" :href="link" :target="targetBlank ? '_blank' : '_self'">
    <span>{{ text }}</span>
  </a>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    targetBlank: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style scoped>
.button {
  padding: 14px 25px;
  display: block;
  width: max-content;
  color: #fff;
  font-weight: 600;
  text-align: center;
  background-color: #7693fc;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #91A9FD;
  }

  @media screen and (max-width: 600px) {
    max-width: 343px;
    width: 100%;
  }
}
</style>
