<template>
  <section class="testimonials padding">
    <div class="container">
      <div class="testimonials__content">
        <h2 class="testimonials__title">Our product is in the spotlight!</h2>
        <Testimonial
          :img="`${require(`@/images/chris-misterek.jpg`)}`"
          quote="Site Palette is another great Chrome Extension that will analyze a site’s color palette and then list it out for you."
          author="Chris Misterek"
          job="Creator of Self-Made Web Designer"
          link="https://selfmadewebdesigner.com/how-to-build-a-web-design-side-hustle-with-no-experience-complete-manual/"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Button from "@/components/UI/RegularButton";
import Testimonial from "@/components/Testimonial";

export default {
  components: { Button, Testimonial },
};
</script>

<style scoped>
* {
  color: #17191d;
  background: #fefefe;
}

.padding {
  padding: 0 20px;
}

.container {
  width: 100%;
  max-width: 1224px;
  margin: 0 auto;
}

.testimonials__content {
  padding: 120px 0 0px;
}

.testimonials__title {
  font-weight: 600;
  font-size: 48px;
  line-height: 62px;
  letter-spacing: -0.02em;
  color: #17191d;
  text-align: center;
  margin-bottom: 48px;
}

.section-block {
  margin: 48px auto 0;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1070px) {
  .section-block {
    max-width: 574px;
  }

  .testimonials__title {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 800px) {
}

@media screen and (max-width: 600px) {
  .padding {
    padding: 0 16px;
  }
  .testimonials__content {
    padding: 50px 0 20px;
  }
  .testimonials__title {
    font-size: 36px;
    line-height: 44px;
  }
}
</style>
