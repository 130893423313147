import firsPostImage from "@/images/posts/blog-article-one.webp";

const { protocol, hostname, port } = window.location;
const domain = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

const blogUrl = `${domain}/blog/`;

export const POSTS = [
  {
    title: "A Quick Guide on How to Use Site Palette Extension",
    description:
      "Site Palette helps streamline your color selection process by extracting palettes directly from any website and offering easy export options for popular design tools.",
    url: blogUrl + "how-to-use-site-palette-extension",
    date: "13 Nov 2024",
    image: firsPostImage,
  },
];
